import React, {useEffect} from 'react'
import LoginForm from '../components/LoginForm'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Context } from '../components/Context'
import {useContext} from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {

	const {setIsLogged, setUser} = useContext(Context);
	const navigate = useNavigate()

	useEffect(() => {
		const userInfo = localStorage.getItem('user');
		if(userInfo){
			setIsLogged(true)
			const u = JSON.parse(userInfo)
			setUser(u.username)
			navigate('/')
		}
	})
  
	return (
		<div>
			<Header />
			<LoginForm />
			<Footer />
		</div>
	)
}

export default Login