import React, { createContext } from 'react'
import { useState } from 'react';

export const Context = createContext(null);


export const ContextProvider = (props) => {
    const [isLogged, setIsLogged] = useState(false)
    const [user, setUser] = useState(null)

    const contextValue = {isLogged, setIsLogged, user, setUser}

    return (
        <Context.Provider value={contextValue}>{props.children}</Context.Provider>
    )
}
