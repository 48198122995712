const Texts = {
    "design_to_process_title" : "A COMPREHENSIVE PLATFORM TO",
    "design_to_process_text" : "It takes on the complexities of managing spatial data and provides tools for users to understand how their data evolves across time and space.",
    "target_mining_title" : "Mining",
    "target_mining_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "target_medical_title" : "Medical",
    "target_medical_text" : "Game developers and creators of virtual worlds can utilize Voxel Space to store and manage their complex spatial data.",
    "target_engineering_title" : "Environment",
    "target_engineering_text" : "Game developers and creators of virtual worlds can utilize Voxel Space to store and manage their complex spatial data.",
    "target_construction_title" : "Construction",
    "target_construction_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "target_architecture_title" : "Architecture",
    "target_architecture_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "target_environment_title" : "Environment",
    "target_environment_text" : "Professionals working with large-scale, high-resolution spatial datasets for research or analysis, in areas like urban planning.",
    "target_entertainment_title" : "Entertainment",
    "target_entertainment_text" : "Professionals working with large-scale, high-resolution spatial datasets for research or analysis, in areas like urban planning.",
    "target_oilandgas_title" : "Oil and Gas",
    "target_oilandgas_text" : "Entities that require large-scale spatial data, such as geographical information systems (GIS) and mining companies",
    "feature_section_1_title" : "Large-Scale Data Handling",
    "feature_section_1_text" : "Whether it's capturing the details of the real world or creating expansive virtual worlds, Voxel Space can store it.",
    "feature_section_2_title" : "Detailed Data Storage",
    "feature_section_2_text" : "Our platform can preserve an impressive level of detail, enabling the storage of spatial data at the sub-micrometer scale.",
    "feature_section_3_title" : "Temporal Data Tracking",
    "feature_section_3_text" : "Recognizing the deep connection between time and space, we offers tools to comprehend how spatial data alters over time.",
    "feature_section_4_title" : "Cloud-Based Service",
    "feature_section_4_text" : "Our platform can preserve an impressive level of detail, enabling the storage of spatial data at the sub-micrometer scale.",
    "visualize_subtitle" : "Visualize Spatial data across multiple rendering systems:",
    "visualize_descriptions" : "Web-based systems and applications developed with cutting-edge rendering engines like Unity3D, Unreal Engine 4, and UNIGINE",
    "storage_subtitle" : "The platform can preserve an impressive level of detail.",
    "storage_descriptions" : "It enables the storage of spatial data at the sub-micrometer scale. For example, it could potentially store a scan of a human body down to the level of individual cells.",
    "pricing_title" : {
        "community" : "Community",
        "basic" : "Basic",
        "professional" : "Professional",
    },
    "pricing_user_per_month" : {
        "community" : "Per User Per Month",
        "basic" : "Per User Per Month",
        "professional" : "Per User Per Month",
    },
    "pricing_team" : {
        "community" : "For small teams",
        "basic" : "The supporting team",
        "professional" : "For large business",
    },
    "pricing_use" : {
        "community" : "For personal use includes basic map design",
        "basic" : "For basic business use Reccomended for managers and the supporting team",
        "professional" : "For advanced business use",
    },
    "pricing_list" : {
        "community" : [
            "Unlimited Used Per Location",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "basic" : [
            "Unlimited Used Per Location",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
        "professional" : [
            "Up to 2 users",
            "EMR With E-Record Creator",
            "Client Management",
            "Client Portal",
            "Photo & Document Uploads",
            "Online Scheduling",
            "2-way Appointment Confirmation",
            "Calendar & Resource Management",
        ],
    },
    "faqs" : [
        {
            "question" : "The standard Lorem Ipsum passage?",
            "answer" : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            "question" : "But i must explain to you how all this mistaken idea?",
            "answer" : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)"
        },
        {
            "question" : "Variations of passages of Ipsum available?",
            "answer" : "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source."
        },
        {
            "question" : "Where can I get some?",
            "answer" : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet."
        }
    ]
};

export default Texts;