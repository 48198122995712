import '../App.css';
import ContentBody from '../components/ContentBody';
import Footer from '../components/Footer';
import Header from '../components/Header';

function Home() {
	return (
		<div className="App">
			<Header />
			<ContentBody />
			<Footer />
		</div>
	);
}

export default Home;
