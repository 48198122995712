import React, {useState} from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const Signup = () => {
	const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [retypepassword, setRetypePassword] = useState('')
    
	const handleFirstname = (event) => {
        setFirstname(event.target.value)
        if (event.target.value.length < 2 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleLastname = (event) => {
        setLastname(event.target.value)
        if (event.target.value.length < 1 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

	const handleEmail = (event) => {
        setEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
        if (event.target.value.length < 5){   
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleRetypePassword = (event) => {
        setRetypePassword(event.target.value)
        if (event.target.value.length < 5 || event.target.value !== password) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleAddress = (event) => {
        setAddress(event.target.value)
        if (event.target.value.length < 8 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleCity = (event) => {
        setCity(event.target.value)
        if (event.target.value.length < 3 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handlePostalCode = (event) => {
        setPostalCode(event.target.value)
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const showError = {
        display : (errorMessage !== '') ? "block" : "none"
    }

    const showSuccess = {
        display : (successMessage !== '') ? "block" : "none"
    }
    
    const reset = () => {
        setFirstname('')
        setLastname('')
        setEmail('')
        setPassword('')
        setRetypePassword('')
        setAddress('')
        setCity('')
        setPostalCode('')
        setPhone('')
    }
    const handleSumit = (e) => {
        console.log("Submit")
        e.preventDefault();
        setErrorMessage('')
        setSuccessMessage('')
      
        const firstnameElem = document.querySelector('#firstname');
        const lastnameElem = document.querySelector('#lastname');
        const emailElem = document.querySelector('#email');
        const passwordElem = document.querySelector('#password');
        const retypepasswordElem = document.querySelector('#retypepassword');
        const addressElem = document.querySelector('#address');
        const cityElem = document.querySelector('#city');
        const postalCodeElem = document.querySelector('#postalCode');
        const phoneElem = document.querySelector('#phone');

        if(firstnameElem.classList.contains('is-invalid') || firstname.length === 0){
            firstnameElem.classList.remove('is-valid');
            firstnameElem.classList.add('is-invalid');
            return false;
        }
        if(lastnameElem.classList.contains('is-invalid') || lastname.length === 0){
            lastnameElem.classList.remove('is-valid');
            lastnameElem.classList.add('is-invalid');
            return false;
        }
        if(emailElem.classList.contains('is-invalid') || email.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
            return false;
        }
        if(passwordElem.classList.contains('is-invalid') || password.length === 0){
            passwordElem.classList.remove('is-valid');
            passwordElem.classList.add('is-invalid');
            return false;
        }
        if(retypepasswordElem.classList.contains('is-invalid') || retypepassword.length === 0){
            retypepasswordElem.classList.remove('is-valid');
            retypepasswordElem.classList.add('is-invalid');
            return false;
        }
        if(addressElem.classList.contains('is-invalid') || address.length === 0){
            addressElem.classList.remove('is-valid');
            addressElem.classList.add('is-invalid');
            return false;
        }
        if(cityElem.classList.contains('is-invalid') || city.length === 0){
            cityElem.classList.remove('is-valid');
            cityElem.classList.add('is-invalid');
            return false;
        }
        if(postalCodeElem.classList.contains('is-invalid') || postalCode.length < 5){
            postalCodeElem.classList.remove('is-valid');
            postalCodeElem.classList.add('is-invalid');
            return false;
        }

        if(phone.length < 7){
            phoneElem.classList.remove('is-valid');
            phoneElem.classList.add('is-invalid');
            return false;
        }
      
        const data = {
            firstname : firstname,
            lastname : lastname,
            email : email,
            password : password,
            retypepassword : retypepassword,
            address : address,
            city : city,
            postalCode : postalCode,
            phone : phone
        };

        const endpoint = process.env.REACT_APP_BASE_URL + '/api/signup';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            
            if(response.action === "failed"){
                setErrorMessage(response.msg)
            }else{
                setSuccessMessage(response.msg)
                reset()
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
	
  return (
    <div className='app'>
        <Header />
		<div className="page-content container-fluid">
            <div className="row justify-content-center">
                <div className="col">
                    <div style={{ maxWidth:'720px', padding: '20px',margin:'100px auto', boxShadow:'0 30px 80px rgba(130,169,182,.25)', borderRadius: '10px'}}>
                        <div className="title">
                            <h3 className="box-title mb-3">Create Your Account</h3>
                        </div>
                        <div style={{ marginTop : '50px' }}>
                            <form>
                                <div className="row">
                                    <div className="
                                        alert alert-danger alert-dismissible
                                        bg-danger
                                        text-white
                                        border-0
                                        fade
                                        show
                                        " role="alert" style={showError}>
                                        <strong>Error - </strong> {errorMessage}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="
                                        alert alert-success alert-dismissible
                                        bg-success
                                        text-white
                                        border-0
                                        fade
                                        show
                                        " role="alert" style={showSuccess}>
                                        <strong>Success - </strong> {successMessage}
                                    </div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="firstname" className="form-label">First Name</label>
                                    <input type="text" className="form-control" id="firstname" value={firstname} onChange={handleFirstname}/>
                                    <div className="invalid-feedback">Please provide with a valid First Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="lastname" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" id="lastname" aria-describedby="emailHelp" value={lastname} onChange={handleLastname}/>
                                    <div className="invalid-feedback">Please provide with a valid Last Name </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="lastname" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" placeholder="Emaiil" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                                    <div className="invalid-feedback">Please provide with a valid Email </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" autoComplete='on' value={password} onChange={handlePassword}/>
                                    <div className="invalid-feedback">Please provide with a valid Password (min 5 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="retypepassword" className="form-label">Retype Password</label>
                                    <input type="password" className="form-control" id="retypepassword" autoComplete='on' value={retypepassword} onChange={handleRetypePassword}/>
                                    <div className="invalid-feedback">The passwords should match </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <input type="text" className="form-control" id="address" value={address} onChange={handleAddress} />
                                    <div className="invalid-feedback">Please provide with a valid Password (min 8 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input type="text" className="form-control" id="city" value={city} onChange={handleCity}/>
                                    <div className="invalid-feedback">Please provide with a valid Password (min 3 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="postalcode" className="form-label">Postal Code</label>
                                    <input type="text" className="form-control" id="postalCode" value={postalCode} onChange={handlePostalCode}/>
                                    <div className="invalid-feedback">Please provide with a valid Postal Code (min 5 characters) </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <PhoneInput
                                        className="form-control"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        id="phone"
                                        onChange={setPhone}/>
                                    <div className="invalid-feedback">Please provide with with a valid Phone Number </div>
                                    <div className="valid-feedback"></div>
                                </div>
                                <button type="submit" className="btn btn-primary" onClick={handleSumit}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <Footer />
    </div>
  );
}

export default Signup;
