import React  from 'react';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Myaccount from './Pages/Myaccount';
import Signup from './Pages/Signup';
import ForgotPassword from './Pages/ForgotPassword';
import ProtectedRoutes from './components/ProtectedRoutes';

import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";

import { ContextProvider } from './components/Context';

function App() {

	return (
		<ContextProvider>
		<Router>
			<Routes>
				<Route path = "/" element={<Home/>} /> 
				<Route path = "/login" element={<Login/>} />
				<Route path = "/signup" element={<Signup/>} />
				<Route path = "/forgot-password" element={<ForgotPassword/>} />
				<Route element={<ProtectedRoutes/>}>
					<Route path = "/myaccount" element={<Myaccount/>} />
				</Route>
			</Routes>
		</Router>
		</ContextProvider>
	);
}

export default App;
