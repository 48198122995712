import React from 'react'
import Texts from './Texts'
import { useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { animateScroll as scroll } from "react-scroll";

const ContentBody = () => {

	const inputRef = useRef(null);

    const handleEmail = () => {
        const value = inputRef.current.value
        console.log(value)
        document.getElementById('inlineFormInputGroup').value = '';
        
        toast.success("Thank you for your registration", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored"
        });
    }

    const handleKeyPress = (e) => {
        // This is perfectly safe in react, it correctly detect the keys
        if(e.key === 'Enter'){
            handleEmail()
        }
    }

	const addHighlight = (e) => {
		e.target.classList.add('highlight')
	}

	const removeHighlight = (e) => {
		e.target.classList.remove('highlight')
	}

	const handleClick = (e) => {
		if(e.target.parentNode.parentNode.classList.contains('selected'))
			e.target.parentNode.parentNode.classList.remove('selected')
		else
			e.target.parentNode.parentNode.classList.add('selected')
	}

	return (
		
		<div className="bodyContainer" id="home">
			<ToastContainer />
			<section className="containerfull design" id="design">
				<div className="content">
					<div className="text">
						<div className="headline">{Texts.design_to_process_title}</div>
						<div className="title">process, store, & visualize vast spatial datasets.</div>
						<div className="description text-medium">{Texts.design_to_process_text}</div>
						<div className="btn-startnow">Learn More</div>
					</div>
					<div className="image"><img src="/images/hero-img-right.png" alt="Design to process" width="100%"/></div>
				</div>
			</section>
			<section className="containerfull banner" id="banner">
				<div className="content">
					<div className="banners">
						{/* <div className="banner banner1"></div>
						<div className="banner banner2"></div>
						<div className="banner banner3"></div>
						<div className="banner banner4"></div>
						<div className="banner banner5"></div>
						<div className="banner banner6"></div> */}
						<img src="/images/clients.jpg" alt="banners"/>
					</div>
				</div>
			</section>
			<section className="containerfull industry" id="industry">
				<div className="content">
					<div className="banner-title"><span>Target</span> Industries</div>
					<div className="sectors">
						<div className="sector">
							<div className="industry-logo mining"></div>
							<div className="industry-title">{Texts.target_mining_title}</div>
							<div className="industry-description">{Texts.target_mining_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo gas"></div>
							<div className="industry-title">{Texts.target_oilandgas_title}</div>
							<div className="industry-description">{Texts.target_oilandgas_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo gas"></div>
							<div className="industry-title">{Texts.target_engineering_title}</div>
							<div className="industry-description">{Texts.target_engineering_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo environment"></div>
							<div className="industry-title">{Texts.target_environment_title}</div>
							<div className="industry-description">{Texts.target_environment_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo construction"></div>
							<div className="industry-title">{Texts.target_construction_title}</div>
							<div className="industry-description">{Texts.target_construction_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo architecture"></div>
							<div className="industry-title">{Texts.target_architecture_title}</div>
							<div className="industry-description">{Texts.target_architecture_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo architecture"></div>
							<div className="industry-title">{Texts.target_medical_title}</div>
							<div className="industry-description">{Texts.target_medical_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						<div className="sector">
							<div className="industry-logo architecture"></div>
							<div className="industry-title">{Texts.target_entertainment_title}</div>
							<div className="industry-description">{Texts.target_entertainment_text}</div>
							<div className="moreinfos">
								<i className="fa fa-arrow-circle-right"></i>
							</div>
						</div>
						
					</div>
				</div>
			</section>
			
			<section className="containerfull features" id="features">
				<div className='content alt-font'>
					<div className="title">Our features</div>
					<div className="headline">
						<span className="bold">becoming</span>
						<span>data centric</span>
					</div>
					<div className="options">
						<div className="option">
							<div className="field field1">
								<div className="container-image">
									<div className="image"></div>
								</div>
								<div className="text">
									<div className="title">{Texts.feature_section_1_title}</div>
									<div className="description text-medium">{Texts.feature_section_1_text}</div>
								</div>
							</div>
						</div>
						<div className="option">
							<div className="field field2">
								<div className="container-image">
									<div className="image"></div>
								</div>
								<div className="text">
									<div className="title">{Texts.feature_section_2_title}</div>
									<div className="description text-medium">{Texts.feature_section_2_text}</div>
								</div>
							</div>
						</div>
						<div className="option">
							<div className="field field3">
								<div className="container-image">
									<div className="image"></div>
								</div>
								<div className="text">
									<div className="title">{Texts.feature_section_3_title}</div>
									<div className="description text-medium">{Texts.feature_section_3_text}</div>
								</div>
							</div>
						</div>
						<div className="option">
							<div className="field field4">
								<div className="container-image">
									<div className="image"></div>
								</div>
								<div className="text">
									<div className="title">{Texts.feature_section_4_title}</div>
									<div className="description text-medium">{Texts.feature_section_4_text}</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>

			<section className="containerfull data" id="learn">
				<div className="content">
					<div className="text">
						{/* <div className="headline">VOXEL SPACE</div> */}
						<div className="title alt-font"><span>Multi-platform </span><br/>visualization</div>
						<div className="subtitle">{Texts.visualize_subtitle}</div>
						<div className="description  text-medium">{Texts.visualize_descriptions}</div>
						<div className="btn-learnmore">continue reading</div>
					</div>
					<div className="image"><img src="/images/section01-800x828.jpg" alt="Design to process" width="100%"/></div>
				</div>
			</section>

			<section className="containerfull parallel">
				<div className="content">
					<div className="text">
						{/* <div className="headline">VOXEL SPACE</div> */}
						<div className="title alt-font"><span>detailed </span><br/>data storage</div>
							<div className="subtitle">{Texts.storage_subtitle}</div>
							<div className="description  text-medium">{Texts.storage_descriptions}</div>
						
						<div className="btn-learnmore">continue reading</div>
					</div>
					<div className="image"><img src="/images/section02-800x828.png" alt="Design to process" width="100%"/></div>
				</div>
			</section>

			<section className="containerfull pricing" id="pricing">
				<div className="contentfull">
					<div className="text">
						{/* <div className="headline">pricing</div> */}
						<div className="title alt-font"><span>pricing </span>plans</div>
					</div>
					<div className="items">
						<div className="item" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="title">{Texts.pricing_title.community}</div>
							<div className="price"><span className="currency">$</span>541</div>
							<div className="peruser">{Texts.pricing_user_per_month.community}</div>
							<div className="team">{Texts.pricing_team.community}</div>
							<div className="description">{Texts.pricing_use.community}</div>
							<div className="options">
								{Texts.pricing_list.community.map((item,i) => <div key={i} className="option text-medium"><span>{item}</span></div>)}
							</div>
							<div className="btn-learnmore center small">Choose plan</div>
						</div>
						<div className="item" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="title">{Texts.pricing_title.basic}</div>
							<div className="price"><span className="currency">$</span>30</div>
							<div className="peruser">{Texts.pricing_user_per_month.basic}</div>
							<div className="team">{Texts.pricing_team.basic}</div>
							<div className="description">{Texts.pricing_use.basic}</div>
							<div className="options">
							{Texts.pricing_list.basic.map((item,i) => <div key={i} className="option text-medium"><span>{item}</span></div>)}
							</div>
							<div className="btn-learnmore center small">Choose plan</div>
						</div>
						<div className="item popular" onMouseEnter={addHighlight} onMouseLeave={removeHighlight}>
							<div className="most_popular">Most popular</div>
							<div className="title">{Texts.pricing_title.professional}</div>
							<div className="price"><span className="currency">$</span>67</div>
							<div className="peruser">{Texts.pricing_user_per_month.professional}</div>
							<div className="team">{Texts.pricing_team.professional}</div>
							<div className="description">{Texts.pricing_use.professional}</div>
							<div className="options">
							{Texts.pricing_list.professional.map((item,i) => <div key={i} className="option text-medium"><span>{item}</span></div>)}
							</div>
							<div className="btn-learnmore center small">Choose plan</div>
						</div>
					</div>
				</div>
			</section>	
		
			<section className="containerfull faqs" id="help">
				<div className="contentfull">
					<div className="text">
						{/* <div className="headline">FAQ</div> */}
						<div className="title"><span>A FREQUENTLY </span>ASKED QUESTIONS</div>
					</div> 
					<div className="faqs">
						{
							Texts.faqs.map((item, i) => 
								<div key={i} className="faq">
								<div className="question">
									<div className="number">{i+1}</div>
									<div className="text" onClick={handleClick}>{item.question}</div>
									<div className="icon" onClick={handleClick}></div>
								</div>
								<div className="answer">{item.answer}</div>
							</div>
							)
						}
					</div>
				</div>
			</section>
			<section className="containerfull newsletter" id="newsletter">
				<div className="contentfull">
					<div className="text title-large">Don't sleep on all the latest from Voxel Space</div>
					<div className="field">
						<div className="form-row align-items-center">
							<div className="col-auto">
								<label className="sr-only" htmlFor="inlineFormInputGroup">Email</label>
								<div className="input-group mb-2">
									<input type="text" className="form-control" id="inlineFormInputGroup" ref={inputRef} placeholder="Email" onKeyUp={handleKeyPress}/>
									<div className="input-group-prepend">
										<div className="input-group-text" onClick={handleEmail}>@</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</div>
	)
}

export default ContentBody