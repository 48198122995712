import React from 'react';
import { Outlet, Navigate } from "react-router-dom";

const useAuth = () => {
    const name = localStorage.getItem('user');
    if (name && name.username !== '')
        return true;
    else
        return false;
}

const ProtectedRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to="/login" />;
}



export default ProtectedRoutes;