import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Myaccount = () => {
  return (
    <div>
        <Header />
        <div className="bodyContainer">
			<section className="containerfull">
				
				<div className="content" style={{margin:'100px auto'}}>
					
					<div className="card" style={{maxWidth: '18rem'}}>
						<Link to="/myaccount/address/edit/1">
							<img className="card-img-top" src={process.env.PUBLIC_URL + '/assets/orders.png'} alt="Your Orders" />
							<div className="card-body">
								<h5 className="card-title">Your Orders</h5>
								<p className="card-text">Cancel an order, upgrade your plan or download invoice</p>
							</div>
						</Link>
					</div>
					
					<div className="card" style={{width: '18rem'}}>
						<Link to="/myaccount/address/edit/1">
							<img className="card-img-top" src={process.env.PUBLIC_URL + '/assets/address.png'} alt="Address" />
							<div className="card-body">
								<h5 className="card-title">Address</h5>
								<p className="card-text">Edit, remove or set default address</p>
							</div>
						</Link>
					</div>
					
					<div className="card" style={{width: '18rem'}}>
						<Link to="/myaccount/address/edit/1">
							<img className="card-img-top" src={process.env.PUBLIC_URL + '/assets/security.png'} width="80px" alt="Login & security" />
							<div className="card-body">
								<h5 className="card-title">Login & security</h5>
								<p className="card-text">Manage password, email and mobile number</p>
							</div>
						</Link>
					</div>
				</div>
			</section>
        </div>
        <Footer />
    </div>
  );
}

export default Myaccount;
