import React, {useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const handleEmail = (event) => {
        setEmail(event.target.value)
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = process.env.REACT_APP_BASE_URL + '/user/forgot-password';
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method : "POST",
            body : JSON.stringify({email : email})
        })
        .then(response => response.json())
        .then(response => {
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                return false;
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }


    return (
        <div>
            <ToastContainer />
            <Header />
            <div className="page-content container-fluid">
                <div className="row justify-content-center">
            
                    <div style={{ maxWidth:'400px' }}>
                        <h1>Forgot Password</h1>
                        <div className="card" style={{ minWidth:'300px',marginTop:'250px', marginBottom:'250px' }}>
                            <div className="card-body">
                                <div className="card-title"><h1>Recover password</h1></div>
                                    <form className="">
                                    <div className="mb-3 form-group required">
                                        
                                        <input type="text" className="form-control" id="email" placeholder="Emaiil" aria-describedby="emailHelp" value={email} onChange={handleEmail}/>
                                    </div>
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <Footer />
        </div>
    );
}

export default ForgotPassword;
