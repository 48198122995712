import React, {useState} from 'react'
import {useContext} from 'react'
import { Context } from './Context';
import {HashLink} from "react-router-hash-link"

const LoginForm = () => {

    const {setIsLogged, setUser} = useContext(Context);
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
  
    const showError = {
        display : (errorMessage !== '') ? "block" : "none"
    }

    const showSuccess = {
        display : (successMessage !== '') ? "block" : "none"
    }

    const handleEmail = (event) => {
        if (event.target.value.length < 5  || 
            event.target.value.split("").filter(x => x === "@").length !== 1 || event.target.value.indexOf(".") === -1) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handlePassword = (event) => {
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const validateForm = (email, password) => {
        if (email.length < 5  || 
            email.split("").filter(x => x === "@").length !== 1 || email.indexOf(".") === -1)
            return false;

        if (password.length < 5)
            return false;

        return true;
    }

    const handleSumit = (e) => {
        e.preventDefault();
        setErrorMessage('')
        setSuccessMessage('')
        const emailElem = document.querySelector('#emailinput');
        const passwordElem = document.querySelector('#passwordinput');
          
       
        if(emailElem.value.length === 0){
            emailElem.classList.remove('is-valid');
            emailElem.classList.add('is-invalid');
        }

        if(passwordElem.value.length === 0){
            passwordElem.classList.remove('is-valid');
            passwordElem.classList.add('is-invalid');
        }

        if(!validateForm(emailElem.value, passwordElem.value))
            return; 

        const data = {
            email : emailElem.value,
            password : passwordElem.value
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/login';

        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
                     
            if(!response || response.action === "failed" || typeof response === "undefined"){
                setErrorMessage(response.msg)
            }else{
               
                setTimeout(() => {
                    localStorage.setItem("user", JSON.stringify(response.user)); 
                    setIsLogged(true)
                    setUser(response.user.firstname)
                    window.location.replace("/");
                }, 1000);
            }
            
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        <>
               
        <div className="bodyContainer">
			<section className="containerfull">
				<div className="content" style={{margin:'100px auto'}}>
                    <div id="loginform">
                        <div className="title">
                            <h4 className="box-title mb-3">Please sign in to authenticate to the Voxel Space.</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <form className="mt-3 form-horizontal">
                                <div className="row">
                                    <div className="
                                        alert alert-danger alert-dismissible
                                        bg-danger
                                        text-white
                                        border-0
                                        fade
                                        show
                                        " role="alert" style={showError}>
                                        <strong>Error - </strong> {errorMessage}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="
                                        alert alert-success alert-dismissible
                                        bg-success
                                        text-white
                                        border-0
                                        fade
                                        show
                                        " role="alert" style={showSuccess}>
                                        <strong>Success - </strong> {successMessage}
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Email</label>
                                    <div className="col-sm-12">
                                        <input type="email" className="form-control" id="emailinput" onChange={handleEmail}/>
                                        <div className="invalid-feedback">Please provide with a valid email </div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Password</label>
                                    <div className="col-sm-12">
                                        <input type="password" className="form-control" id="passwordinput" autoComplete="on" onChange={handlePassword}/>
                                        <div className="invalid-feedback">Please provide with a valid password</div>
                                        <div className="valid-feedback"></div>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                <button className="btn-info" type="submit" onClick={handleSumit}>Submit form</button>
                                </div>
                                <div className="forget-password text-medium"><HashLink to="/forgot-password">Forgot password</HashLink></div>
                                <div className="new-account text-medium"><HashLink to="/signup">Create your account</HashLink></div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}

export default LoginForm