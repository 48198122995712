import { useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import { useRef } from "react";
import React from 'react'
import {useContext} from 'react'
import { Context } from './Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
	const [hamburger, setHamburger] = useState(true);
	const [isMobile, setIsMobile] = useState(false)
	const [showPanel, setShowPanel] = useState(false)
	const screenSize = useRef();
	const {isLogged, setIsLogged, user, setUser} = useContext(Context);

	const navigate = useNavigate()
	const [activeSection, setActiveSection] = useState(null);
  	const sections = useRef([]);

	const handleScroll = () => {
		const pageYOffset = window.scrollY;
		let newActiveSection = null;

		sections.current.forEach((section) => {
			const sectionOffsetTop = section.offsetTop - 100;
			const sectionHeight = section.offsetHeight;
			if (pageYOffset >= sectionOffsetTop && pageYOffset  < sectionOffsetTop + sectionHeight) {
				newActiveSection = section.id;
			}
		});
		setActiveSection(newActiveSection);
	};


	useEffect(() => {
		
		const userInfo = localStorage.getItem('user');
		if(userInfo){
			setIsLogged(true)
			const u = JSON.parse(userInfo)
			setUser(u.firstname)
		}
        window.addEventListener("resize", () => {
            screenSize.current = window.innerWidth;
			if(window.innerWidth > 992)
				setIsMobile(false)
			else
				setIsMobile(true)
        });
        // return () => {
        //     window.removeEventListener("resize", () => {
        //         screenSize.current = window.innerWidth;
        //     })
        // }

		sections.current = document.querySelectorAll('section');
	   	window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
    });

	const handleClickMenu = (e, val) => {

		if(e.target.parentNode.parentNode.classList.contains('opened'))
			e.target.parentNode.parentNode.classList.remove('opened')
		else
			e.target.parentNode.parentNode.classList.add('opened')

		if(val === true){
			setHamburger(false)
		}
		else{
			setHamburger(true)
		}
		console.log(val)
	}

	const showMyAccountPanel = () => {
		setShowPanel(true)
	}

	const hideMyAccountPanel = () => {
		setShowPanel(false)
	}

	const logout = (e) => {
		e.preventDefault();
		setIsLogged(false)
        setUser(null)
        localStorage.removeItem('user')
        navigate('/login')
	}

  	return (
		<>
    	<div className="nav">
			<div className="icon">
				{
				hamburger ? 
				<i className="fa fa-bars" onClick={(e) => handleClickMenu(e, hamburger)}></i>
				:
				<i className="fa fa-close" onClick={(e) => handleClickMenu(e, hamburger)}></i>
				}
			</div>
			
			<HashLink to="/#design">
				<div className="logo">
					{/* <div className="brand"><HashLink to="/#design" smooth>VOXEL</HashLink></div>
					<div className="vline">&nbsp;</div>
					<div className="name">SPACE</div> */}
				</div>
			</HashLink>
			{!isMobile || (isMobile && !hamburger) ? 
			<>
			<div className="menu">
				{/* <div className="menu_item"><Link className="menu_item" to="/">Home</Link></div> */}
				<div className="menu_item text-medium"><HashLink className={activeSection === 'industry' ? 'active' : ''} to="/#industry" smooth>Industry</HashLink></div>
				<div className="menu_item text-medium"><HashLink className={activeSection === 'features' ? 'active' : ''} to="/#features" smooth>Features</HashLink></div>
				<div className="menu_item text-medium"><HashLink className={activeSection === 'learn' ? 'active' : ''} to="/#learn" smooth>Learn</HashLink></div>
				<div className="menu_item text-medium"><HashLink className={activeSection === 'pricing' ? 'active' : ''} to="/#pricing" smooth>Pricing</HashLink></div>
				<div className="menu_item text-medium"><HashLink className={activeSection === 'about' ? 'active' : ''} to="/#about" smooth>About</HashLink></div>
				<div className="menu_item text-medium"><HashLink className={activeSection === 'help' ? 'active' : ''} to="/#help" smooth>Help</HashLink></div>
				<div className="menu_item text-medium">
					{/* <div className="m text-mediumenu-right" onMouseEnter={showMyAccountPanel}> */}
					<div className="menu-right" >
						{
						isLogged ? 
							<div className="myaccount-container" onMouseEnter={showMyAccountPanel} onMouseLeave={hideMyAccountPanel}>
								<div className="myaccount-title text-medium">Bonjour, {user} <FontAwesomeIcon icon={faChevronDown} color="#00659D" /></div>
								{showPanel ? 
									<div className="myaccount-panel">
										<div className="myaccount-item"><a href="/myaccount">Your Account</a></div>
										<div className="myaccount-item"><a href="/#" onClick={(e) => logout(e)}>Sign Out</a></div>
									</div>
								: ''
								}
							</div>
						:
						<HashLink to="/login" className="login"><div>Login</div></HashLink>
						}
					</div>
				</div>
			</div>
			
			</>
			: 
			''
			}

		</div>
		{showPanel ? 
			<div id="nav-overlay"></div>
		:
			''
		}
		</>
  	)
}

export default Header