import React from 'react'
import {HashLink} from "react-router-hash-link"


const Footer = () => {

    return (
    <> 
    <div className="footer">
        <div className="containerfull">
            <div className="content">
                <div className="footer-left">
                    <div className="footer-logo"></div> 
                    <div className="description">
                    A comprehensive solution for processing, analyzing, and utilizing point cloud data for various industries.
                    </div>  
                </div> 
                <div className='links-container'>
                <div className="links">
                    <div className="lists">
                        <div className="title text-medium font-weight-600">Quick Links</div>
                        <div className="item text-medium"><HashLink to="/#features" smooth>Features</HashLink></div>
                        <div className="item text-medium"><HashLink to="/#pricing" smooth>Pricing</HashLink></div>
                        <div className="item text-medium"><HashLink to="/#about" smooth>Contact Us</HashLink></div>
                    </div>
                </div>
                <div className="links">
                    <div className="lists">
                        <div className="title text-medium font-weight-600">Company</div>
                        <div className="item text-medium"><HashLink to="/#" smooth>Privacy</HashLink></div>
                        <div className="item text-medium"><HashLink to="/#" smooth>Terms</HashLink></div>
                    </div>
                </div>
                <div className="links">
                    <div className="lists">
                        <div className="title text-medium font-weight-600">Follow Us</div>
                        <div className="item text-medium"><HashLink to="/#" smooth>Twitter</HashLink></div>
                        <div className="item text-medium"><HashLink to="/#" smooth>YouTube</HashLink></div>
                       
                    </div>
                </div>
                </div>
            </div>
            <div className="trademark">
                <div className="copyright">&copy; 2023 Voxel Space</div>
                <div className="socials">
                    <div className="social twitter"></div>
                    <div className="social youtube"></div>
                </div> 
            </div>  
        </div> 
    </div>
    </>
  )
}

export default Footer